import { BackgroundColorProps } from './BackgroundColor.types';
import { Container, InnerContainer, Spacer, WaveWrapper, WaveWrapperBottom } from './BackgroundColor.styles';

export const BackgroundColor = ({
  children,
  backgroundColor,
  hasWave,
}: React.PropsWithChildren<BackgroundColorProps>) => {
  return (
    <Container $backgroundColor={backgroundColor}>
      {hasWave ? (
        <WaveWrapper>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 110">
            <path d="M 0 10 C 100 150 300 0 500 80 L 500 0 L 0 0" fill="#FFF" opacity=".5"></path>
            <path d="M 0 20 C 100 150 330 -30 500 50 L 500 0 L 0 0" fill="#FFF" opacity=".7"></path>
            <path d="M 0 30 C 115 150 250 0 500 100 L 500 0 L 0 0" fill="#FFF" opacity=".8"></path>
          </svg>
        </WaveWrapper>
      ) : (
        <Spacer />
      )}
      <InnerContainer>{children}</InnerContainer>
      {hasWave ? (
        <WaveWrapperBottom>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 110">
            <path d="M 0 10 C 100 150 300 0 500 80 L 500 0 L 0 0" fill="#FFF" opacity=".5"></path>
            <path d="M 0 20 C 100 150 330 -30 500 50 L 500 0 L 0 0" fill="#FFF" opacity=".7"></path>
            <path d="M 0 30 C 115 150 250 0 500 100 L 500 0 L 0 0" fill="#FFF" opacity=".8"></path>
          </svg>
        </WaveWrapperBottom>
      ) : (
        <Spacer />
      )}
    </Container>
  );
};
