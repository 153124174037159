import styled from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const Container = styled.section<{
  $backgroundColor?: string;
  $hasWave?: boolean;
}>`
  background-color: ${(props) => props.$backgroundColor || Colors.ContrastOverlay};
  transform: translateX(calc(-1 * var(--right-left-global-padding)));
  width: calc(100% + (var(--right-left-global-padding) * 2));
`;
export const InnerContainer = styled.div`
  padding: 0 var(--right-left-global-padding);
`;

export const WaveWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transform: translateY(-10px);
  /* z-index: -1; */
`;

export const WaveWrapperBottom = styled.div`
  position: relative;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: rotate(180deg) translateY(-10px);
  /* z-index: -1; */
`;

export const Spacer = styled.div`
  height: var(--spacer-large);
`;
